// extracted by mini-css-extract-plugin
export var backdrop = "index-module--backdrop--34940";
export var button = "index-module--button--0f5ba";
export var collapsed = "index-module--collapsed--853c1";
export var collapsing = "index-module--collapsing--a374a";
export var dialog = "index-module--dialog--fcb19";
export var expanded = "index-module--expanded--85d14";
export var expanding = "index-module--expanding--e7b96";
export var heading = "index-module--heading--c5f77";
export var item = "index-module--item--4e597";
export var menu = "index-module--menu--0a602";