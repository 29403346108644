import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Menu from '../menu'
import * as styles from './index.module.css'

const Header = ({
  siteTitle,
  breadcrumbText = '',
  breadcrumbLink = '',
  showSiteTitle = true,
}) => {

  let breadcrumb
  if (breadcrumbLink && breadcrumbText) {
    if (breadcrumbText.toLowerCase() === "back") {
      breadcrumb = <button onClick={e => window.history.back()}>{breadcrumbText.toLowerCase()}</button>
    } else {
      breadcrumb = <Link to={breadcrumbLink}>{breadcrumbText.toLowerCase()}</Link>
    }
  } else if (breadcrumbText) {
    breadcrumb = breadcrumbText.toLowerCase()
  }

  let content
  if (showSiteTitle && breadcrumb) {
    content = (
      <h1>
        <Link to="/">{siteTitle.replace(/\/$/, '')}</Link>/{breadcrumb}
      </h1>
    )
  } else if (showSiteTitle) {
    content = (
      <h1>
        <Link to="/">{siteTitle.replace(/\/$/, '')}</Link>/
      </h1>
    )
  } else if (breadcrumb) {
    content = <h1>{breadcrumb}</h1>
  }

  const [expanded, setExpanded] = useState(false)

  // Shorthand function that will toggle the menu's expanded state
  const toggler = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <header className={`${styles.header} js-header heading`}>
        {content}
        <button
          className={`button--reset inherit-type-styles ${styles.button}`}
          aria-expanded={expanded}
          onClick={toggler}
        >
          menu
        </button>
      </header>
      <Menu expanded={expanded} handler={setExpanded} />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
