import React, { useEffect, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import * as styles from './index.module.css'

const BREAKPOINT_FULL_SCREEN = "fullScreen"
const BREAKPOINT_HANGING_FROM_TOP = "hangingFromTop"

const Menu = (props) => {
  const data = useStaticQuery(query)

  const dialogHolder = useRef(null)
  const rootElement = useRef(null)
  const dialog = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const [isAnimating, setAnimatingState] = useState(false)
  const animateExpandedState = useRef(setExpanded)
  const [breakpoint, setBreakpoint] = useState(null);

  useEffect(() => {
    const onResize = () => {
      setBreakpoint(
        window.innerWidth > 640 ? BREAKPOINT_FULL_SCREEN : BREAKPOINT_HANGING_FROM_TOP
      )
    }
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  })

  // When the menu is not expanded, it should be `visibility: hidden`
  // so that screen readers don't announce it. But, we want to animate
  // the menu in and out; and so the menu must have visiblity until the
  // the animation is complete (otherwise it won't be seen!).
  // Therefore we use two states that operate in conjunction.
  useEffect(() => {
    if (!dialog.current) {
      return
    }

    dialog.current.addEventListener('transitionend', (e) => {
      setAnimatingState(false)
    })

    animateExpandedState.current = (update) => {
      setAnimatingState(true)

      // Elements with `display: none` cannot be transitioned, so we
      // need to change the `isAnimating` state first. Then we have
      // let the browser paint that change before we animate.
      // Otherwise, the browser would optimize the changes to `display`
      // and `opacity` such that they happen at the same time.
      // A simple timeout is enough to signal to the browser to handle
      // these changes separately.
      setTimeout(() => {
        setExpanded(update)
      }, 1)
    }
  }, [dialog])

  // The header's "menu" button should open the menu. This component
  // is a child of the header. When that button is clicked, the
  // header passes along a value to this component as a prop.
  // Therefore, we must watch the props for changes.
  useEffect(() => {
    if (props.expanded === expanded) {
      return
    }

    animateExpandedState.current(props.expanded)
  }, [props.expanded])

  // Set up hook so that when the expanded state changes, the window will scroll lock
  useEffect(() => {
    if (expanded) {
      document.body.style.setProperty('overflow', 'hidden')
    } else {
      document.body.style.removeProperty('overflow')
    }

    return () => document.body.style.removeProperty('overflow')
  }, [expanded])

  // Set up hook so that clicking outside the menu when it's expanded will in turn collapse it
  useEffect(() => {
    const onClickOutside = (e) => {
      if (
        !rootElement.current ||
        rootElement.current.contains(e.target) ||
        e.target.matches('.js-header *') ||
        expanded
      ) {
        return
      }

      props.handler(false)
    }

    document.addEventListener('click', onClickOutside)
    return () => {
      document.removeEventListener('click', onClickOutside)
    }
  }, [rootElement])

  const handleBackdropClick = () => {
    // On desktop, clicking the backdrop shouldn't do anything
    if (breakpoint === BREAKPOINT_FULL_SCREEN) {
      return
    }

    // But otherwise, on mobile, clicking the backdrop should collapse the menu
    props.handler(false)
  }

  const getClassName = () => {
    if (expanded && isAnimating) {
      return styles.expanding
    } else if (expanded) {
      return styles.expanded
    } else if (isAnimating) {
      return styles.collapsing
    } else {
      return styles.collapsed
    }
  }

  return (
    <nav
      ref={rootElement}
      className={[styles.menu, getClassName(), 'heading'].join(' ')}
    >
      <div ref={dialogHolder}>
        <div
          ref={dialog}
          className={styles.dialog}
          style={{ top: `${dialogHolder.current?.offsetTop || 0}px` }}
          aria-hidden={expanded}
        >
          <ul>
            {data.strapiMenu.links.map((menuItem) => (
              <li key={menuItem.id} className={styles.item}>
                <Link to={menuItem.link}>{menuItem.label}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.backdrop} onClick={handleBackdropClick}></div>
      </div>
    </nav>
  )
}

const query = graphql`
  query MenuQuery {
    strapiMenu {
      links {
        label
        link
        id
      }
    }
  }
`

export default Menu
