import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import '../styles/index.css'

const Layout = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query siteTitleQuery {
      strapiGlobal {
        siteTitle
      }
    }
  `)

  return (
    <div className="page">
      <Header
        siteTitle={data.strapiGlobal.siteTitle}
        showSiteTitle={props.showSiteTitle}
        breadcrumbText={props.breadcrumbText}
        breadcrumbLink={props.breadcrumbLink}
      />
      {children}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
